import React from "react"
import styled from "styled-components";
import { keyframes } from "styled-components";

const styles = {
    container: {
        fontFamily: 'Jost, Arial',
        color: 'white',
    },
    background: {
        // backgroundImage: `url('https://rebellion.s3.us-west-2.amazonaws.com/background.png')`,
        backgroundColor: '#1e2022',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        overflow: 'hidden',
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: '100',
    },
    rebIconMed: {
        height: '70px',
    },
    rebellionText: {
        marginLeft: '15px',
        fontSize: '24px',
        fontWeight: '500',
    },
    daoText: {
        fontSize: '30px',
        marginLeft: '15px',
        fontWeight: '700',
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '20px',
        boxSizing: 'border-box',
    },
    nav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '100',
        fontSize: '14px',
        fontWeight: '600',
    },
    navButton: {
        margin: '0 20px',
        fontSize: '18px',
        cursor: 'pointer',
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxSizing: 'border-box',
        alignItems: 'stretch',
    },
    rebelImages: {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        zIndex: 100,
    },
    mainInfo: {
        flexBasis: '50%',
        marginBottom: '50px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '50px',
        zIndex: 100,
    },
    callToAction: {
        fontSize: '70px',
        fontWeight: '600',
        lineHeight: '1.2',
        marginBottom: '40px',
        maxWidth: '600px',
    },
    subText: {
        fontSize: '24px',
        fontWeight: '600',
        marginBottom: '40px',
        maxWidth: '600px',
    },
    buttons: {
        display: 'flex',
    },
    secondaryButton: {
        padding: '10px 20px',
        fontSize: '30px',
        border: '1px solid #FFF',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    logoContainer: {
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    primaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px 20px',
        fontSize: '30px',
        fontWeight: '600',
        borderRadius: '20px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    headerPrimaryButton: {
        backgroundColor: '#00b3ff',
        padding: '10px 20px',
        fontSize: '24px',
        fontWeight: '600',
        borderRadius: '20px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    rebelImage: {
        height: '200px',
        marginLeft: '-50px',
        marginRight: '-50px',
    },
    aboutSection: {
        backgroundColor: '#EEE',
        width: '100%',
        overflow: 'hidden',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '50px',
        color: '#222',
        boxSizing: 'border-box',
    },
    leftSection: {

    },
    rightSection: {
        marginLeft: '50px',
    },
    sectionTitle: {
        fontWeight: '700',
        fontSize: '40px',
    },
    aboutText: {
        fontWeight: '500',
        fontSize: '24px',
        maxWidth: '800px',
    },
    dots: {
        width: '100%',
    },
    roadmapSection: {
        color: '#222',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        boxSizing: 'border-box',
    },
    teamSection: {
        backgroundColor: '#EEE',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        color: '#222',
        boxSizing: 'border-box',
    },
    teamMembers: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    teamMember: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '50px 50px'
    },
    teamImage: {
        border: '1px solid #222',
        borderRadius: '20px',
    },
    teamName: {
        fontSize: '28px',
        fontWeight: '700',
        marginTop: '5px',
    },
    teamRole: {
        fontSize: '18px',
        fontWeight: '600',
        color: '#333',
    },
    teamSocials: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    navIcon: {
        fontSize: '34px',
    },
    answer: {
        fontSize: '18px',
        fontWeight: '500',
        maxWidth: '700px',
        textAlign: 'left',
        overflow: 'hidden',
        padding: '10px 0',
        boxSizing: 'border-box',
        transition: 'all 0.3s ease 0s'
    },
    bar: {
        maxWidth: '700px',
        width: '100%',
        height: '3px',
        backgroundColor: '#222',
    },
    footerSection: {
        backgroundColor: '#EEE',
        width: '100%',
        padding: '50px',
        boxSizing: 'border-box',
        color: '#222',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}

const shrink = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0)
  }
`

const PrimaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #007fb4 !important;
}
`

const SecondaryButton = styled.div`
transition: all 0.3s ease 0s;
:hover {
    background-color: #FFF !important;
    border-color: #FFF !important;
    color: #333 !important;
}
`

class FAQ extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            answers: {}
        }
    }

    createFaq = (faq, index) => {
        return (
            <div style={{...styles.aboutText, textAlign: 'center', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div onClick={this.showAnswer.bind(this, index)} style={{maxWidth: '700px', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{fontWeight: '700', cursor: 'pointer'}}>
                        {`${faq.question}`}
                    </div>
                    <div style={{fontWeight: '700'}}>
                        {`${this.state.answers[index] ? '-' : '+'}`}
                    </div>
                </div>
                <div style={{...styles.answer, maxHeight: this.state.answers[index] ? '300px' : '0', padding: this.state.answers[index] ? '10px 0' : '0'}}>
                    {faq.answer}
                </div>
                <div style={styles.bar} />
            </div>
        )
    }

    faqs = () => {
        let qa = [
            {
                question: 'What is a Rebel NFT?',
                answer: 'A Rebel NFT is an NFT that gives you rights to the daily distribution of $REB tokens and can be used to sponsor citizens of the Rebellion DAO.'
            },
            {
                question: 'What is $REB?',
                answer: '$REB is the native currency of the Rebellion ecosystem. It is distributed to Rebel NFT holders & Rebellion Citizens.'
            },
            {
                question: 'Wen mint?',
                answer: 'The first mint will happen soon, but the exact date has not yet been announced. Make sure to join our discord to stay up to date!'
            },
            {
                question: 'What blockchain is this on?',
                answer: 'The Rebellion ecosystem is currently located on the Polygon blockchain, but will eventually migrate to its own separate blockchain.'
            },
            {
                question: 'How do I mint a Rebel NFT?',
                answer: 'In order to mint a Rebel NFT you will need 5000 $REB tokens. These tokens can be acquired via purchase on Quickswap. Please check our docs for more information.'
            },
            {
                question: 'What makes this project different?',
                answer: 'We are building an ecosystem around the project that extends far beyond just NFTs. We are working on creating an entirely new currency that can be used for the masses.'
            },
            {
                question: 'How many Rebel NFTs will be minted?',
                answer: 'There is no exact number of Rebel NFTs will be minted. This depends on the supply & demand of the REB token. When the token price is not rising fast enough, more Rebel NFTs will be minted to put more purchasing pressure on the $REB token. When the price is too high Rebel NFT mints will be kept to a minimum.'
            },
            {
                question: 'What is Rebellion Citizenship?',
                answer: 'The Rebellion DAO is controlled via a democratic system. All Rebellion Citizens both have the right to vote on the future of the project and also receive daily distributions of $REB tokens.'
            },
            {
                question: 'How do you become a citizen?',
                answer: 'To become a citizen of the Rebellion DAO you must either be sponsored by a Rebel NFT or purchase citizenship with $REB tokens. Please check the docs for additional information.'
            },
            {
                question: 'How do you acquire $REB?',
                answer: 'To acquire $REB tokens you will need to buy them off of a Decentralized Exchange on polygon such as quickswap. Please check the docs for a full tutorial.'
            },
            {
                question: 'What is the best way to get involved?',
                answer: 'Join the discord, engage with the community, become a Rebellion Citizen, and purchase a Rebel NFT! These are all great ways to contribute to the project',
            },
        ]

        let res = []

        for (let i = 0; i < qa.length; i++) {
            res.push(this.createFaq(qa[i], i))
        }

        return res
    }

    showAnswer = (number) => {
        this.state.answers[number] = !this.state.answers[number]
        this.setState({answers: this.state.answers})
    }

    render() {
        return this.faqs()
    }

}

class MainPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mobile: window.innerWidth < 1000,
            small: window.innerWidth < 1400,
        }
    }

    generateRandomDot = () => {
        let delay = Math.floor(Math.random() * 3000)

        let up = 400 + Math.floor(Math.random() * 400)
        const goUp = keyframes`
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(-${up}px);
          }
        `

        let Container = styled.div`
            position: absolute;
            animation: ${goUp} 3s ease-in ${delay}ms infinite;
            top: ${this.state.small ? '1100' : '650'}px;
            left: ${(Math.random()*120) - 20}%;
        `

        let size = 50 + Math.floor(Math.random() * 400)
        let Dot = styled.div`
            height: ${size}px;
            width: ${size}px;
            border-radius: 50%;
            background-color: #16181A;
            animation: ${shrink} 3s linear ${delay}ms  infinite;
        `

        return (
            <Container>
                <Dot />
            </Container>
        )
    }
    scrollTo = (element) => {
        let my_element = document.getElementById(element)

        my_element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    windowSizeChange = () => {
        this.setState({mobile: window.innerWidth < 1000, small: window.innerWidth < 1400})
    }

    componentDidMount = async() => {
        window.addEventListener("resize", this.windowSizeChange.bind(this));
    }

    generateManyDots = () => {
        let dots = []
        for (let i = 0; i < 100; i++) {
            dots.push(this.generateRandomDot())
        }
        return dots
    }

      render () {
            return (
                <div style={styles.container}>
                  <div style={styles.background}>
                      <div style={{...styles.header, justifyContent: this.state.mobile ? 'center' : 'space-between'}}>
                          <a href={'/'}>
                              <div style={styles.logo}>
                                  <div style={styles.logoContainer}>
                                      <img style={styles.rebIconMed} src={'https://nanobash.s3.us-west-2.amazonaws.com/fist2.png'} />
                                  </div>
                                  <div>
                                      <div style={styles.rebellionText}>
                                          {`Rebellion`}
                                      </div>
                                      <div style={styles.daoText}>
                                          {`REBELS`}
                                      </div>
                                  </div>
                              </div>
                          </a>
                          <div style={{...styles.nav, padding: this.state.mobile ? '20px 0 0' : '0'}}>
                              <div onClick={this.scrollTo.bind(this, "about")} style={styles.navButton}>
                                  About
                              </div>
                              <div onClick={this.scrollTo.bind(this, "roadmap")} style={styles.navButton}>
                                  Roadmap
                              </div>
                              <div onClick={this.scrollTo.bind(this, "team")} style={{...styles.navButton, marginRight: this.state.mobile ? '0' : '0 20px'}}>
                                  Team
                              </div>
                              <div onClick={this.scrollTo.bind(this, "faq")} style={{...styles.navButton, padding: this.state.mobile ? '20px 0 0' : '0'}}>
                                  FAQ
                              </div>
                              <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                  <PrimaryButton style={{...styles.headerPrimaryButton, width: '70px', height: '70px', padding: '0', margin: this.state.mobile ? '20px 0 0' : '0', borderRadius: '50%'}}>
                                      <i className="fab fa-discord" style={styles.navIcon} />
                                  </PrimaryButton>
                              </a>
                              <a target={"_blank"} href={'https://twitter.com/RebellionBase'}>
                                  <PrimaryButton style={{...styles.headerPrimaryButton, width: '70px', height: '70px', padding: '0', margin: this.state.mobile ? '20px 0 0 20px' : '0 0 0 20px', borderRadius: '50%'}}>
                                      <i className="fab fa-twitter" style={styles.navIcon} />
                                  </PrimaryButton>
                              </a>
                          </div>
                      </div>
                      <div style={{...styles.mainContent, flexFlow: this.state.small ? 'column-reverse wrap' : 'row no-wrap', padding: this.state.small ? '50px 0 0' : '100px 100px 0'}}>
                          <div style={{...styles.rebelImages, justifyContent: this.state.small ? 'center' : 'space-between'}}>
                              <div>
                                  <img style={{...styles.rebelImage, height: '400px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebel1.png'} />
                              </div>
                              <div style={{display: this.state.mobile ? 'none' : 'block'}}>
                                  <img style={{...styles.rebelImage, height: '350px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebel4.png'} />
                              </div>
                              <div style={{display: this.state.mobile ? 'none' : 'block'}}>
                                  <img style={{...styles.rebelImage, height: '300px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebel3.png'} />
                              </div>
                          </div>
                          <div style={{...styles.mainInfo, marginLeft: this.state.small ? '0' : '50px', padding: this.state.small ? '20px 20px 0' : '0'}}>
                              <div style={{...styles.callToAction, fontSize: this.state.mobile ? '50px' : '70px', textAlign: this.state.small ? 'center' : 'left'}}>
                                  JOIN THE REBELLION
                              </div>
                              <div style={{...styles.subText, textAlign: this.state.small ? 'center' : 'left'}}>
                                  Rebellion aims to bridge NFTs and finance. Rebel NFTs generate in excess of $200+ per year in passive income via their utility.
                              </div>
                              <div style={{...styles.buttons, maxWidth: '600px', width: '100%', justifyContent: this.state.mobile ? 'space-around' : 'flex-start'}}>
                                  <a target={"_blank"} href={'https://docs.rebelliondao.com/'}>
                                      <SecondaryButton style={{...styles.secondaryButton, fontSize: this.state.mobile ? '22px' : '30px'}}>
                                          View Docs
                                      </SecondaryButton>
                                  </a>
                                  <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                      <PrimaryButton style={{...styles.primaryButton, marginLeft: '20px', fontSize: this.state.mobile ? '22px' : '30px'}}>
                                          Join Discord
                                      </PrimaryButton>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div style={styles.dots}>
                          {this.generateManyDots()}
                      </div>
                  </div>
                    <div id={"about"} style={{...styles.aboutSection, flexFlow: this.state.small ? 'row wrap' : 'row no-wrap', padding: this.state.mobile ? '50px 50px 0' : '50px'}}>
                        <div style={styles.leftSection}>
                            <div style={styles.sectionTitle}>
                                About
                            </div>
                            <br />
                            <div style={styles.aboutText}>
                                Rebellion isn’t your standard NFT project. We are building an ecosystem that will bring new meaning to what an NFT project can achieve. We are bridging concepts between NFTs, DeFi, and economies of scale to create a new paradigm in finance.
                                <br />
                                <br />
                                As a Rebel NFT holder you will be entitled to daily $REB distributions (The ecosystems native currency) starting at $200+ per year that scales as the project grows. This is done to reward holders that have long-term conviction in the project.
                                <br />
                                <br />
                                This isn’t your normal worthless NFT token. Rebellion is laser focused around creating long term demand & utility for the $REB currency. This will be accomplished via the tokenomics laid out below.
                                <br />
                                <br />
                                In our roadmap we lay out our plan to create an entirely custom blockchain with $REB as its native currency as well as an incubator to fund companies that build projects around the Rebellion ecosystem. This is all done to create value and utility for the token outside of just this project to create a long-term sustainable model.
                            </div>
                        </div>
                        <div style={styles.rightSection}>
                            <img style={{...styles.rebelImage, height: this.state.small ? '400px' : '700px', margin: this.state.mobile ? '0 0 0 -50px' : '0 -50px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebel2.png'} />
                        </div>
                    </div>
                    <div id={"roadmap"} style={styles.roadmapSection}>
                        <div style={styles.sectionTitle}>
                            Roadmap
                        </div>
                        <br />
                        <div style={{...styles.aboutText, textAlign: 'center'}}>
                            <span style={{fontWeight: '700'}}>
                                1. Launch Rebel NFTs Contract (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            Rebel NFT contract is currently live and 100 Rebel NFTs have been pre-minted to be used in giveaways!
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                2. Launch $REB token with $30,000 LP (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            The $REB token that powers the Rebellion ecosystem has been launched and seeded with $30,000 worth of liquidity!
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                3. Launch Rebellion Citizenship (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            Rebellion Citizenship has now been launched. Citizenship can be obtained either via a Rebel NFT or purchasing citizenship with $REB.
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                4. Implement $REB Distributions for NFT Holders & Citizens (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            Rebel NFT holders & Rebellion Citizens can now claim their daily $REB allowance directly from RebellionBase.com
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                5. Launch Bounty Program (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            Bounty program awarding referrals, holding $REB, and promoting the Rebellion DAO has been launched!
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                6. Rebellion Websites Launched (✔️ Completed)
                            </span>
                            <br />
                            <br />
                            RebellionBase.com website officially live! Information regarding the Rebellion ecosystem can be found here!
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                7. First Rebel NFT Mint (Q1 2022)
                            </span>
                            <br />
                            <br />
                            First official Rebel NFT mint is scheduled for Q1 2022. A limited edition of Rebel NFTs will be mint-able for 5000 $REB tokens.
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                8. DAO Voting Implemented (Q2 2022)
                            </span>
                            <br />
                            <br />
                            Rebellion DAO citizens having full control of the project reaching true decentralization!
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                9. Rebellion Incubator Program (Q3 2022)
                            </span>
                            <br />
                            <br />
                            Incubator program launched where the Rebellion DAO begins investing in companies that utilize the $REB currency.
                            <br />
                            <br />
                            <br />
                            <span style={{fontWeight: '700'}}>
                                10. Rebellion Blockchain Released (Q4 2022)
                            </span>
                            <br />
                            <br />
                            Full independent blockchain launch for the Rebellion ecosystem creating even more burn mechanisms for the $REB token.
                        </div>
                    </div>
                    <div id={"team"} style={styles.teamSection}>
                        <div style={styles.sectionTitle}>
                            Meet the Team
                        </div>
                        <div style={styles.teamMembers}>
                            <div style={styles.teamMember}>
                                <img style={{borderRadius: '20px', height: '300px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/1_level4.png'} />
                                <div style={styles.teamName}>
                                    Rebel
                                </div>
                                <div style={styles.teamRole}>
                                    Founder & Developer
                                </div>
                                <div style={styles.teamSocials}>
                                    <a target={"_blank"} href={'https://twitter.com/RebelFounder'}>
                                        <PrimaryButton style={{...styles.headerPrimaryButton, width: '50px', color: 'white', height: '50px', padding: '0', margin: '0 10px', borderRadius: '50%'}}>
                                            <i className="fab fa-twitter" style={{fontSize: '28px'}} />
                                        </PrimaryButton>
                                    </a>
                                    <a target={"_blank"} href={"https://www.linkedin.com/in/zaguios/"}>
                                        <PrimaryButton style={{...styles.headerPrimaryButton, width: '50px', color: 'white', height: '50px', padding: '0', margin: '0 10px', borderRadius: '50%'}}>
                                            <i className="fab fa-linkedin" style={{fontSize: '28px'}} />
                                        </PrimaryButton>
                                    </a>
                                </div>
                            </div>
                            <div style={styles.teamMember}>
                                <img style={{borderRadius: '20px', height: '300px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebels/staging/mini/35_level2.png'} />
                                <div style={styles.teamName}>
                                    LenickiMinaj
                                </div>
                                <div style={styles.teamRole}>
                                    Marketing
                                </div>
                                <div style={styles.teamSocials}>

                                </div>
                            </div>
                            <div style={styles.teamMember}>
                                <img style={{borderRadius: '20px', height: '300px'}} src={'https://rebellion.s3.us-west-2.amazonaws.com/rebels/mini/33_level3.png'} />
                                <div style={styles.teamName}>
                                    Mr. Boombastic
                                </div>
                                <div style={styles.teamRole}>
                                    Community & Outreach
                                </div>
                                <div style={styles.teamSocials}>

                                </div>
                            </div>
                        </div>
                        {/*<div style={{...styles.aboutText, textAlign: 'center'}}>*/}
                        {/*    Something something*/}
                        {/*</div>*/}
                    </div>
                    <div id={"faq"} style={styles.roadmapSection}>
                        <div style={styles.sectionTitle}>
                            FAQ
                        </div>
                        <br />
                        <FAQ />
                    </div>
                    <div id={"footer"} style={styles.footerSection}>
                        <div style={{...styles.sectionTitle, marginBottom: '30px'}}>
                            Join Now!
                        </div>
                        <div style={{...styles.buttons, justifyContent: 'center'}}>
                            <a target={"_blank"} href={'https://docs.rebelliondao.com/'}>
                                <SecondaryButton style={{...styles.secondaryButton, border: '1px solid #333', fontSize: this.state.mobile ? '16px' : '30px'}}>
                                    View Docs
                                </SecondaryButton>
                            </a>
                            <a target={"_blank"} href={'https://discord.gg/a4egATVu8r'}>
                                <PrimaryButton style={{...styles.primaryButton, marginLeft: '20px', color: 'white', fontSize: this.state.mobile ? '16px' : '30px'}}>
                                    Join Discord
                                </PrimaryButton>
                            </a>
                        </div>
                    </div>
                </div>
            );
      }
}

export default MainPage
